<template>
  <transition-group name="fade">
    <div class="p-5" v-if="show" key="administradorAdministradores">
      <ModalNovoEditarUsuario :objPerfil="objPerfil" />
      <ModalEditarInvestAdmin />
      <ModalConfirmacao
        :vontade="'excluir'"
        :elementoVontade="templateElementoVontade"
        :condicional="false"
        :dispararEvento="'delUser'"
        :valorParaDispatch="objToDispatch"
      />
      <div class="bg-fff in-here shadow-sm border-default shadow-sm">
        <div id="dados">
          <AdministradorMeusDadosComponent />
        </div>
        <div id="administradores">
          <AdministradorAdministradores
            :administradoresData="getAdministradores"
            @mudaNomeModal="novoAdministrador"
            @editarAdminInvest="editUser"
            @delUser="delUser"
            @editUser="editUser"
          />
        </div>
        <div id="administradores">
          <AdministradorInvestidores
            :investidoresData="getInvestidores"
            @mudaNomeModal="novoAdministrador"
            @editarAdminInvest="editUser"
            @delUser="delUser"
            @editUser="editUser"
          />
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import AdministradorMeusDadosComponent from "@/components/administrador/AdministradorMeusDadosComponent";
import AdministradorAdministradores from "@/components/administrador/AdministradorAdministradores";
import AdministradorInvestidores from "@/components/administrador/AdministradorInvestidores";
import ModalNovoEditarUsuario from "@/components/modais/ModalNovoEditarUsuario";
import ModalConfirmacao from "@/components/modais/ModalConfirmacao";
import ModalEditarInvestAdmin from "@/components/modais/ModalEditarInvestAdmin";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      show: false,
      objPerfil: {},
      templateElementoVontade: null,
      objToDispatch: {},
    };
  },
  mounted() {
    this.show = true;
    this.getDadosDeTodosUsuarios();
  },
  computed: {
    ...mapGetters(["getAdministradores", "getInvestidores"]),
  },
  components: {
    AdministradorMeusDadosComponent,
    AdministradorAdministradores,
    AdministradorInvestidores,
    ModalNovoEditarUsuario,
    ModalConfirmacao,
    ModalEditarInvestAdmin,
  },
  methods: {
    novoAdministrador(val) {
      this.objPerfil = val;
      this.showModal("modalNameNovoEditarUsuario");
    },
    getDadosDeTodosUsuarios() {
      this.$store
        .dispatch("getDadosDeTodosUsuarios", this.$store.getters.getPerfilToken)
        .then((resolve) => {
          if (resolve.valid) {
            this.showLoader = false;
            this.toastGlobal("success", `Usuários carregados com sucesso.`);

          } else {
            this.showLoader = false;
            this.toastGlobal(
              "error",
              `Algo de errado ocorreu, ${resolve.responseJSON.msg}`
            );
          }
        });
    },
    delUser(val) {
      this.templateElementoVontade = val.name;
      this.objToDispatch = {
        cod: val.id,
        token: this.$store.getters.getPerfilToken,
      };
      this.showModal("modalNameConfirmacao");
    },
    editUser(val) {
      this.$store.commit("SET_ADMININVEST_TO_EDIT", val);
      this.showModal("modalEditarInvestAdmin");
    },
  },
};
</script>

<style scoped></style>
