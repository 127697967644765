<template>
  <div :class="`px-5 pt-5`">
    <div :class="`pl-0 container`">
      <div :class="`bg-fff position-relative`">
        <div class="row">
          <div class="h2 col-md-12">
            <h2 class="text-left mt-5 pb-2 title-dados">Investidor</h2>
          </div>
          <div
            v-for="(item, index) in investidoresData"
            :key="index"
            class="col-md-12 row text-left less-width my-2"
          >
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{ item.name }}</h3>
            </div>
            <div class="col-md-2 d-flex align-items-center">
              <the-mask
                class="no-input-style"
                :mask="['###.###.###-##']"
                :value="item.cpf"
                disabled
              />
            </div>
            <div class="col-md-3 d-flex align-items-center">
              <h3>{{ item.email }}</h3>
            </div>
            <div class="col-md-4 row">
              <!--
              <button
                type="submit"
                class="btn-style-default-less bg-222541 color-fff text-uppercase"
              >
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
              -->
              <button
                type="submit"
                class="btn-style-default-less bg-fedd0a color-fff text-uppercase mx-3"
              >
                <i class="fa fa-edit cor"></i>
              </button>
              <button
                type="submit"
                class="btn-style-default-less bg-d42338 color-fff text-uppercase"
                @click="$emit('delUser', item)"
              >
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-start mt-4">
            <button
              type="submit"
              class="btn-style-default-less bg-0e5daf color-fff text-uppercase mr-5"
            >
              ver todos
            </button>
            <button
              @click="chamaModal()"
              class="btn-style-default-less color-fff bg-0e5daf text-uppercase"
            >
              novo investidor
              <i class="icon-plus ml-1"
                ><img src="@/assets/icones/iconPlus.png" alt="iconPlus.png"
              /></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalNovoEditarUsuario from "@/components/modais/ModalNovoEditarUsuario";
export default {
  data() {
    return {};
  },
  props: ["investidoresData"],
  mounted() {},
  methods: {
    chamaModal() {
      this.$emit("mudaNomeModal", { typePerfil: 2 });
    },
  },

  filter: {},
};
</script>

<style scoped>
h3 {
  font-size: 12px;
  font-weight: 700;
}

.cor {
  color: #000;
}
</style>
