<template>
  <modal
    id="ModalEditarInvestAdmin"
    name="modalEditarInvestAdmin"
    :height="'auto'"
    :width="'50%'"
    :adaptive="true"
    :draggable="true"
  >
    <div class="modalAdicionarTema container py-3">
      <div class="row">
        <div class="col-md-10 mx-auto position-relative">
          <h3 class="h3-modal">
            <strong class="color-fedd0a">Editar</strong>
            {{ verifyStatus($store.getters.getAdminInvest.profile_id) }}
          </h3>
          <ValidationObserver
            ref="form"
            tag="form"
            class="mt-4 text-left row"
            @submit.prevent="editarUsuario()"
          >
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'Nome completo'"
              :bails="false"
              class="col-md-6"
              tag="div"
            >
              <div class="control" :class="classes">
                <label for="">Nome Completo</label>
                <input
                  id="txbName"
                  type="text"
                  v-model="$store.getters.getAdminInvest.name"
                  class="form-control"
                  :class="classes"
                />
              </div>
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="validadeCpf|required"
              name="CPF"
              :bails="false"
              class="col-md-6"
              tag="div"
            >
              <div class="control" :class="classes">
                <label for="">CPF</label>
                <input
                  id="txbCpf"
                  v-mask="'###.###.###-##'"
                  class="form-control"
                  :class="classes"
                  type="text"
                  v-model="$store.getters.getAdminInvest.cpf"
                />
                <div class="text-left">
                  <span v-bind="ariaMsg" class="span-status-validate">{{
                    errors[0]
                  }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'E-mail'"
              :bails="false"
              class="col-md-6"
              tag="div"
            >
              <div class="control mt-4" :class="classes">
                <label for="">E-mail</label>
                <input
                  id="txbEmail"
                  type="email"
                  v-model="$store.getters.getAdminInvest.email"
                  class="form-control"
                  :class="classes"
                />
              </div>
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, ariaMsg, classes }"
              rules="required"
              name="'Senha'"
              :bails="false"
              class="col-md-6"
              tag="div"
            >
              <div class="control mt-4" :class="classes">
                <label for="">Senha</label>
                <input
                  id="txbSenha"
                  type="password"
                  v-model="$store.getters.getAdminInvest.password"
                  class="form-control"
                  :class="classes"
                />
              </div>
              <span v-bind="ariaMsg" class="span-status-validate">{{
                errors[0]
              }}</span>
            </ValidationProvider>
            <div
              class="mt-4 btns d-flex align-items-center justify-content-center"
            >
              <button
                class="btn-style-default color-fff bg-d42338"
                @click="hideModal('modalNameNovoEditarUsuario')"
              >
                cancelar
              </button>
              <button
                class="btn-style-default color-fff bg-0e5caf ml-3"
                type="submit"
                :disabled="show"
              >
                {{ statusEdicao }}
                <div v-if="show" class="spinner-border" role="status">
                  <span class="sr-only"></span>
                </div>
              </button>
              <!--<button class="btn-style-default color-fff bg-0e5caf ml-3" @click="chamaProximaModalLinkaTema()">próximo</button>-->
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      selecting: "SELECIONAR",
      typePerfil: null,
      statusEdicao: "Salvar",
      show: false,
    };
  },
  props: ["objPerfil"],
  mounted() {},
  methods: {
    changeSelect(val) {
      this.selecting = val;
    },
    chamaProximaModalLinkaTema(val) {
      this.$emit("chamadaProximaModalLinkaTema", val);
    },
    editarUsuario() {
      this.$refs.form.validate().then((success) => {
        let objToEdit = {
          cod: this.$store.getters.getAdminInvest.id,
          name: document.getElementById("txbName").value,
          cpf: document.getElementById("txbCpf").value,
          email: document.getElementById("txbEmail").value,
          password: document.getElementById("txbSenha").value,
        };
        if (success) {
          this.$store.dispatch("editUser", objToEdit).then((resolve) => {
            if (resolve.valid) {
              this.toastGlobal(
                "success",
                `O usuário ${this.$store.getters.getAdminInvest.name} foi alterado com sucesso.`
              );

              this.hideModal("modalEditarInvestAdmin");
            } else {
              this.toastGlobal("error", resolve.msg.reject.msg);
            }
          });
        } else {
          this.toastGlobal("error", "Preencha o formulário devidamente.");
        }
      });
    },
    resetFormulario() {
      this.selecting = "SELECIONAR";
      this.objNovoAdministrador.name = null;
      this.objNovoAdministrador.cpf = null;
      this.objNovoAdministrador.email = null;
      this.objNovoAdministrador.password = null;
      this.show = false;
    },
  },
};
</script>

<style scoped></style>
