<template>
  <div :class="`px-5 pb-5`">
    <div :class="`pl-0 container`">
      <div :class="`bg-fff position-relative`">
        <div id="editarDados" class="position-absolute">
          <button
            type="submit"
            class="btn-style-default-less bg-0e5caf color-fff text-uppercase"
          >
            editar meus dados
          </button>
        </div>
        <div class="row">
          <div class="h2 col-md-12">
            <h2 class="text-left mt-5 pb-2 title-dados">Meus Dados</h2>
          </div>
          <div class="col md-4 text-left less-width">
            <div>
              <label for="" class="mb-2 mt-4">Primeiro Nome</label> <br />
              <input
                id="txbFirstName"
                type="text"
                placeholder="Aluno"
                :value="userFirstName()"
              />
            </div>
            <div>
              <label for="" class="mb-2 mt-4">Último Nome</label> <br />
              <input
                id="txbLastName"
                type="text"
                placeholder="Skaki"
                :value="userLastName()"
              />
            </div>
          </div>
          <div class="col-md-4 less-width">
            <div class="text-left">
              <div>
                <label for="" class="mb-2 mt-4">CPF</label> <br />
                <input
                  id="txbCPF"
                  type="text"
                  v-mask="'###.###.###-##'"
                  placeholder="010.020.030-40"
                  :value="$store.getters.getUserDatas.cpf"
                />
              </div>
              <div>
                <label for="" class="mb-2 mt-4">Senha</label> <br />
                <input
                  id="txbSenha"
                  type="password"
                  placeholder="123456789"
                  :value="$store.getters.getUserDatas.password"
                />
              </div>
            </div>
          </div>
          <div class="col-md-4 text-left less-width">
            <div>
              <label for="" class="mb-2 mt-4">E-mail</label> <br />
              <input
                id="txbEmail"
                type="email"
                placeholder="alguém@e-mail.com"
                :value="$store.getters.getUserDatas.email"
              />
            </div>
            <div
              id="addFoto"
              class="position-absolute d-flex align-items-center justify-content-center"
            >
              <div class="d-flex">
                <div id="fotoPerfilMeusDadosAluno"></div>
                <div class="ml-4">
                  <label
                    for="txbFotoPerfil"
                    class="btn-style-default-less bg-0e5caf color-fff text-uppercase"
                    >alterar foto</label
                  >
                </div>
                <input
                  @change="teste"
                  id="txbFotoPerfil"
                  type="file"
                  name=""
                  hidden
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    editPerfil() {
      this.$refs.form.validate().then((success) => {
        let objToEdit = {
          name: `${document.getElementById("txbFirstName").value}${
            document.getElementById("txbLastName").value
          }`,
          cpf: document.getElementById("txbCPF").value,
          password: document.getElementById("txbSenha").value,
          email: document.getElementById("txbEmail").value,
          fotoPerfil: document.getElementById("txbFotoPerfil").value,
        };
        if (success) {
          this.$store.dispatch("editUser", objToEdit).then((resolve) => {
            if (resolve.valid) {
              this.toastGlobal(
                "success",
                `O usuário ${this.$store.getters.getAdminInvest.name} foi alterado com sucesso`
              );

              this.hideModal("modalEditarInvestAdmin");
            } else {
              this.toastGlobal("error", resolve.msg.reject.msg);
            }
          });
        } else {
          this.toastGlobal("error", "Preencha o formulário devidamente.");
        }
      });
    },
    // teste() {
    //   alert(document.getElementById("txbFotoPerfil").value);
    // },
  },
};
</script>

<style scoped>
.less-width input {
  width: 75%;
}

input::placeholder {
  font-size: 10px !important;
  color: #454c57;
}

#addFoto {
  bottom: -10px;
}

#addFoto label:hover {
  cursor: pointer;
}

#fotoPerfilMeusDadosAluno {
  background-color: #cbccd3;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-image: url("../../assets/aluno/perfilDefault.png");
}

.here h2 {
  border-bottom: 1px solid #e4e5e8;
  font-size: 14px;
}

#editarDados {
  bottom: -75px;
}
</style>
